<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      title="Edit data"
      ok-only
      ok-title="Exit"
    >
      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-form enctype="multipart/form-data" @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding form-item-section">
                <b-row ref="row" class="pb-2">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Campaign Name">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignName"
                            v-model="campaignName"
                            type="text"
                            placeholder="name"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignName == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="limit">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignNumber"
                            v-model="campaignNumber"
                            type="number"
                            placeholder="limit"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignNumber == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group label="Campaign Detail">
                        <b-input-group class="input-group-merge">

                          <quill-editor
                            :options="snowOption"
                            id="campaignDetail"
                            v-model="campaignDetail"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignDetail == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group><br><br><br>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Hour">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignHour"
                            v-model="campaignHour"
                            type="number"
                            placeholder="Campaign Hour"
                            step="0.01"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignHour == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign status">
                        <b-form-checkbox
                          v-model="campaignStatus"
                          checked="true"
                          class="custom-control-success"
                          name="check-button"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Date Start">
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="campaignDateStart"
                            v-model="campaignDateStart"
                            placeholder="Campaign Date Start"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignDateStart == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Date End">
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="campaignDateEnd"
                            v-model="campaignDateEnd"
                            placeholder="Campaign Date End"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignDateEnd == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <!-- image -->
                      <div v-if="check.campaignImage == null">
                        <b-row class="match-height">
                          <b-col>
                            <b-container fluid class="p-1 bg-dark">
                              <b-row>
                                <b-col>
                                  <b-img
                                    thumbnail
                                    fluid
                                    :src="
                                      require('@/assets/images/img/cloud-computing.png')
                                    "
                                  />
                                </b-col>
                              </b-row>
                            </b-container>
                            <b-form-file
                              placeholder="อัพโหลดรูป"
                              ref="refInputEl"
                              type="file"
                              accept=".jpeg,.png,.jpg,GIF"
                              :hidden="true"
                              @change="onFileChange"
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div class="pa-5" id="Box" v-else>
                        <b-row class="match-height">
                          <b-col>
                            <b-container fluid class="p-1 bg-dark">
                              <b-row>
                                <b-col>
                                  <!-- ********** -->
                                  <b-button
                                    v-if="campaignImage"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    size="sm"
                                    variant="relief-danger"
                                    @click="delete_img"
                                  >
                                    delete
                                  </b-button>
                                  <!-- ********** -->

                                  <b-img thumbnail fluid :src="campaignImage" />
                                </b-col>
                              </b-row>
                            </b-container>
                            <b-form-file
                              placeholder="เปลี่ยนรูปภาพ"
                              ref="refInputEl"
                              type="file"
                              accept=".jpeg,.png,.jpg,GIF"
                              :hidden="true"
                              @change="onFileChange"
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <!-- image -->
                    </b-col>
                    <b-col cols="6">
                      <b-button
                        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                        variant="outline-warning"
                        @click="add_code()"
                      >
                        add code
                      </b-button>
                      <b-form-input
                        id="readOnlyInput"
                        :value="amount"
                        readonly
                      />
                      <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                        <!-- Column: Actions -->
                        <template #cell(actions)="data">
                          <b-dropdown variant="link" no-caret>
                            <template #button-content>
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>
                            <b-dropdown-item
                              @click="edit_code(data.item.code)"
                            >
                              <feather-icon icon="EditIcon" />
                              <span class="align-middle ml-50">Edit</span>
                            </b-dropdown-item>

                            <b-dropdown-item
                              @click="delete_code(data.item.code)"
                            >
                              <feather-icon icon="TrashIcon" />
                              <span class="align-middle ml-50">Delete</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-table>
                      <b-card-body
                        class="d-flex justify-content-between flex-wrap pt-0"
                      >
                        <!-- page length -->
                        <b-form-group
                          label="Per Page"
                          label-cols="6"
                          label-align="left"
                          label-size="sm"
                          label-for="sortBySelect"
                          class="text-nowrap mb-md-0 mr-1"
                        >
                          <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                          />
                        </b-form-group>

                        <!-- pagination -->
                        <div>
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </div>
                      </b-card-body>
                      <!-- //************************ */ -->
                    </b-col>
                    <!-- submit and reset -->
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="update()"
                      >
                        Update
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                      >
                        cancal
                      </b-button>
                    </b-col>
                  </b-row>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
    <codeedit ref="modalcode2" @showModaledit="showModaledit(id)"></codeedit>
    <codeadd ref="modalcodeadd2" @showModaledit="showModaledit(id)"></codeadd>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BLink,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BModal,
  BFormText,
  BTable,
  BAlert,
  BFormSelect,
  BPagination,
  pagination,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import codeedit from "./codeedit.vue";
import codeadd from "./codeadd.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BLink,
    BListGroupItem,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BModal,
    BFormText,
    BTable,
    BAlert,
    BFormDatepicker,
    BFormSelect,
    BPagination,
    pagination,
    BDropdown,
    BDropdownItem,
    codeedit,
    BFormCheckbox,
    codeadd,
    quillEditor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      data: {},
      id: null,
            snowOption: {
        theme: 'snow',
      },
      campaignImage: null,
      campaignName: null,
      campaignDetail: null,
      campaignDateStart: null,
      campaignDateEnd: null,
      campaignCode: null,
      campaignStatus: null,
      campaignNumber: null,
      campaign_id: null,
      campaignHour: null,
      campaignType: "hour",
      dialog: null,
      check: {
        campaignImage: false,
        campaignName: false,
        campaignDetail: false,
        campaignDateStart: false,
        campaignDateEnd: false,
        campaignCode: false,
        campaignNumber: false,
        campaignHour: false,
      },
      fields: [
        {
          key: "code",
          label: "Campaign Code",
          sortable: false,
        },
        {
          key: "total",
          label: "limit",
          sortable: false,
        },
        // { key: "actions" },
      ],
      items: [],
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      amount: null,
    };
  },
  mounted() {},
  created() {},
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    add_code() {
      console.log(this.id);
      this.$refs.modalcodeadd2.showModaladd(this.id);
    },
    edit_code(id) {
      this.$refs.modalcode2.showModalcode(id);
      console.log(id);
    },
    delete_code(code) {
      console.log("delete", code);
      const params = {
        code: code,
      };
      Swal.fire({
        title: "ยืนยันการลบข้อมูล",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showLoaderOnConfirm: true,
        preConfirm: () => {},
      }).then((result) => {
        api.post("/deletecode", params).then((response) => {
          console.log(response.data);
          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.showModaledit(this.id);
        });
      });
    },

    update() {
      if (
        Date.parse(this.campaignDateStart) > Date.parse(this.campaignDateEnd)
      ) {
        console.log(this.campaignDateEnd);
        this.campaignDateEnd = null;
        // error date
        this.check.campaignDateEnd = true;

        this.$swal({
          title: "Error!",
          text: " End date should be greater than Start date",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        if (
          this.campaignName &&
          this.campaignDateStart &&
          this.campaignDateEnd &&
          this.campaignHour &&
          this.campaignNumber
        ) {
          Swal.fire({
            title: "ยืนยันการแก้ไขข้อมูล",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: () => {},
          }).then((result) => {
            if (result.isConfirmed) {
              let formData = new FormData();
              formData.append("campaignId", this.campaign_id);
              formData.append("file", this.fileImage);
              formData.append("campaignName", this.campaignName);
              formData.append("campaignDetail", this.campaignDetail);
              formData.append("campaignDateStart", this.campaignDateStart);
              formData.append("campaignDateEnd", this.campaignDateEnd);
              formData.append("campaignNumber", this.campaignNumber);
              formData.append("campaignImage", this.campaignImage);
              formData.append("campaignStatus", this.campaignStatus);
              formData.append("campaignHour", this.campaignHour); //-----------สำคัญ-----------//
              formData.append("campaignType", "hour"); //-----------สำคัญ-----------//

              // console.log(this.campaign_id);
              // console.log(this.fileImage);
              // console.log(this.campaignName);
              // console.log(this.campaignDetail);
              // console.log(this.campaignDateStart);
              // console.log(this.campaignDateEnd);
              // console.log(this.campaignNumber);
              // console.log(this.campaignImage);
              // console.log(this.campaignHour);
              // console.log('campaignType',this.campaignType);

              api
                .post("/campaignupdate", formData)
                .then((response) => {
                  // console.log("response", response.data);
                  if (response) {
                    Swal.fire({
                      icon: "success",
                      title: "success",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.dialog = false;
                    this.refresh();
                  }
                })
                .catch((error) => {
                  console.log(error.response);
                });
            }
          });
        } else {
          if (!this.campaignName) {
            this.check.campaignName = true;
            console.log("campaignName null");
          }
          if (!this.campaignDateStart) {
            this.check.campaignDateStart = true;
            console.log("campaignDateStart null");
          }
          if (!this.campaignDateEnd) {
            this.check.campaignDateEnd = true;
            console.log("campaignDateEnd null");
          }
          if (!this.campaignHour) {
            this.check.campaignHour = true;
            console.log("campaignHour null");
          }

          if (!this.campaignNumber) {
            this.check.campaignNumber = true;
            console.log("campaignNumber null");
          }
          this.$swal({
            title: "Please complete the information.",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    delete_img() {
      this.campaignImage = "";
      this.fileImage = "";
      console.log("set campaignImage=null", this.campaignImage);
    },
    async showModaledit(id) {
      this.check.campaignImage = false;
      this.check.campaignName = false;
      this.check.campaignDetail = false;
      this.check.campaignDateStart = false;
      this.check.campaignDateEnd = false;
      this.check.campaignNumber = false;
      this.check.campaignHour = false;
      console.log("id", id);
      this.id = id;
      const params = {
        id: id,
      };
      // get date axios
      api.post("/campaignsget", params).then((response) => {
        console.log(response.data);
        console.log((this.items = response.data.codes));
        console.log((this.totalRows = response.data.total));
        this.amount = "จำนวนโค้ดทั้งหมด " + this.totalRows + " โค้ด";
        for (let item of response.data.data) {
          (this.campaignName = item.campaign_name),
            (this.campaign_id = item.campaign_id),
            (this.campaignDetail = item.campaign_detail),
            (this.campaignDateStart = item.campaign_dateStart),
            (this.campaignDateEnd = item.campaign_dateEnd),
            (this.campaignNumber = item.campaign_number),
            (this.campaignStatus = item.campaign_status),
            (this.campaignImage = item.campaign_image);
          this.campaignHour = item.campaign_hour;
        }
      });
      this.dialog = true;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFileChange(e) {
      this.fileImage = e.target.files[0];
      console.log("file", this.fileImage);
      const image = e.target.files[0];
      this.campaignImage = URL.createObjectURL(image); ///show
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
  },
  setup() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>