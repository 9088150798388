<template>
  <b-card title="Venue" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">none</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <AddHourCode @loaddata="loaddata"></AddHourCode>
        <EditHourCode @loaddata="loaddata" ref="modal"></EditHourCode>
        <ShowHourCode ref="modalshow"></ShowHourCode>
        <ImportHourCode @loaddata="loaddata"></ImportHourCode>
        <GenerateCodeHour @loaddata="loaddata"></GenerateCodeHour>
        <addtag @loaddata="loaddata" ref="modaladdtag"></addtag>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(image)="data">
        <template>
          <b-avatar size="32" :src="data.item.campaign_image" />
        </template>
      </template>
      <template #cell(campaign_status)="data">
        <template v-if="data.item.campaign_status == 'true'">
          <b-badge variant="success">active </b-badge>
        </template>
        <template v-else>
          <b-badge variant="danger"> inactive </b-badge>
        </template>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item @click="show_data(data.item.campaign_id)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Details</span>
          </b-dropdown-item>
          <b-dropdown-item @click="show_tag(data.item.campaign_id)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Tag</span>
          </b-dropdown-item>

          <b-dropdown-item @click="edit_data(data.item.campaign_id)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="delete_data(data.item.campaign_id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import api from "@/api";
import AddHourCode from "./AddHourCode.vue";
import ImportHourCode from "./ImportHourCode.vue";
import EditHourCode from "./EditHourCode.vue";
import GenerateCodeHour from "./GenerateCodeHour.vue";
import ShowHourCode from "./ShowHourCode.vue";
import addtag from "../addTag.vue";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    AddHourCode,
    ImportHourCode,
    EditHourCode,
    GenerateCodeHour,
    ShowHourCode,
    addtag,
  },
  data() {
    return {
      isAddCampaign: false,
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "image" },
        {
          key: "campaign_name",
          label: "Campaign Name",
        },
        {
          key: "campaign_hour",
          label: "Campaign Hour",
          sortable: false,
        },
        { key: "campaign_number", label: "Total", sortable: true },
        { key: "campaign_status", label: "status", sortable: true },
        {
          key: "campaign_dateStart",
          label: "Campaign DateStart",
          sortable: true,
        },
        {
          key: "campaign_dateEnd",
          label: "Campaign DateEnd",
          sortable: true,
        },
        { key: "actions" },
      ],

      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.loaddata();
  },
  methods: {
    async loaddata() {
      const params = {
        campaign_type: "hour",
      };
      api
        .post("/campaignslist", params)
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.log(response.data);
        })
        .catch((error) => {});
    },
    delete_data(id) {
      console.log("delete", id);
      const params = {
        id: id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api.post("/campaignsdel", params).then((response) => {
            console.log(response.data);
            this.loaddata();
            this.$swal({
              icon: "success",
              title: "Deleted!",
              text: "Your file has been deleted.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
    edit_data(id) {
      console.log("edit_data", id);
      this.$refs.modal.showModaledit(id);
    },
    show_data(id) {
      this.$refs.modalshow.showModaldata(id);
    },
    show_tag(id) {
      this.$refs.modaladdtag.showmodeladdtag(id);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
