<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="showcodeadd"
      title="เพิ่มโค้ด"
      ok-only
      ok-title="Exit"
    >
      <b-form-input id="code" v-model="code" type="text" placeholder="code" />
      <b-alert v-if="check.code == true" variant="danger" show>
        <span>Please Enter</span>
      </b-alert>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        class="mr-1"
        @click="save()"
      >
        Addcode
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BFormInput, BAlert } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import Swal from "sweetalert2";
import api from "@/api";
export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BFormInput,
    BAlert,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      campaign_id: null,
      code: null,
      code_status: null,
      showcodeadd: null,
      check: {
        code: false,
      },
    };
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    refresh(id) {
      this.$emit("showModaledit", "showModaledit");
      console.log('refresh')
    },
    showModaladd(id) {
      this.code = ""
      console.log("id", id);
      this.campaign_id = id;
      this.showcodeadd = true;
    },

    save() {
      if (this.code) {
        Swal.fire({
          title: "ยืนยันการแก้ไขข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => {},
        }).then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            formData.append("campaign_id", this.campaign_id);
            formData.append("code", this.code);
            console.log(this.code);
            api
              .post("/addcode", formData)
              .then((response) => {
                console.log("response", response.data);
                if (response) {
                  Swal.fire({
                    icon: "success",
                    title: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.showcodeadd = false;
                  this. refresh();
                }
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        });
      } else {
        if (!this.code) {
          this.check.code = true;
          console.log("code null");
        }
      }
    },
  },
  setup() {},
};
</script>
