<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
    >
      Add code
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Add Code data"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <b-form-group label="Campaign Name">
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="MailIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                  id="campaignName"
                                  v-model="campaignName"
                                  type="text"
                                  placeholder="name"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.campaignName == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <b-col cols="5">
                            <b-form-group label="Campaign Code">
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="LockIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                  id="campaignCode"
                                  v-model="campaignCode"
                                  type="text"
                                  placeholder="Campaign Code"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.campaignCode == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="5">
                            <b-form-group label="Campaign Hour">
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="LockIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                  id="campaignHour"
                                  v-model="campaignHour"
                                  type="number"
                                  placeholder="Campaign Hour"
                                  step="0.01"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.campaignHour == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <!-- ------------------------------------------- -->
                          <b-col cols="2">
                            <b-form-group label="Campaign status">
                              <b-form-checkbox
                                v-model="campaignStatus"
                                checked="true"
                                class="custom-control-success"
                                name="check-button"
                                switch
                              >
                                <span class="switch-icon-left">
                                  <feather-icon icon="CheckIcon" />
                                </span>
                                <span class="switch-icon-right">
                                  <feather-icon icon="XIcon" />
                                </span>
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                          <!-- ------------------------------------------- -->
                          <b-col cols="12">
                            <b-form-group label="limit">
                              <b-input-group class="input-group-merge">
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="LockIcon" />
                                </b-input-group-prepend>
                                <b-form-input
                                  id="campaignNumber"
                                  v-model="campaignNumber"
                                  type="number"
                                  placeholder="limit"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.campaignNumber == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group label="Campaign Detail">
                              <b-input-group class="input-group-merge">
                               
                               
                                <quill-editor
                                 :options="snowOption"
                                  id="campaignDetail"
                                  v-model="campaignDetail"
                               
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.campaignDetail == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group><br><br><br><br>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Campaign Date Start">
                              <b-input-group class="input-group-merge">
                                <flat-pickr
                                  id="campaignDateStart"
                                  v-model="campaignDateStart"
                                  placeholder="Campaign Date Start"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                              </b-input-group>

                              <b-alert
                                v-if="check.campaignDateStart == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Campaign Date End">
                              <b-input-group class="input-group-merge">
                                <flat-pickr
                                  id="campaignDateEnd"
                                  v-model="campaignDateEnd"
                                  placeholder="Campaign Date End"
                                  class="form-control"
                                  :config="{
                                    enableTime: true,
                                    dateFormat: 'Y-m-d H:i',
                                  }"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.campaignDateEnd == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12">
                            <!-- image -->
                            <div v-if="campaignImage == null">
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="
                                            require('@/assets/images/img/cloud-computing.png')
                                          "
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                  <b-form-file
                                    placeholder="อัพโหลดรูป"
                                    ref="refInputEl"
                                    type="file"
                                    accept=".jpeg,.png,.jpg,GIF"
                                    :hidden="true"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                            </div>
                            <div class="pa-5" id="Box" v-else>
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="campaignImage"
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                  <b-form-file
                                    placeholder="เปลี่ยนรูปภาพ"
                                    ref="refInputEl"
                                    type="file"
                                    accept=".jpeg,.png,.jpg,GIF"
                                    :hidden="true"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                            </div>
                            <!-- image -->
                          </b-col>

                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="save()"
                            >
                              Submit
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              type="reset"
                              variant="outline-secondary"
                              @click="reset()"
                            >
                              Reset
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>

                  <!-- <span class="font-weight-bold">รูปแคมเปญ </span> -->
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BLink,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
      quillEditor,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BLink,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormCheckbox,
  
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
      campaignImage: null,
      campaignName: null,
      campaignDetail: null,
      campaignDateStart: null,
      campaignDateEnd: null,
      campaignCode: null,
      campaignNumber: 1,
      campaignStatus: true,
      campaignHour: null,
      snowOption: {
        theme: 'snow',
      },
      check: {
        campaignImage: false,
        campaignName: false,
        campaignDetail: false,
        campaignDateStart: false,
        campaignDateEnd: false,
        campaignCode: false,
        campaignNumber: false,
        campaignHour: false,
      },
    };
  },
  watch: {},
  mounted() {},
  created() {},
  computed: {},
  methods: {
    onFileChange(e) {
      this.fileImage = e.target.files[0];
      console.log("fileImage", this.fileImage);
      const image = e.target.files[0];
      this.campaignImage = URL.createObjectURL(image); ///show
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      if (
        Date.parse(this.campaignDateStart) > Date.parse(this.campaignDateEnd)
      ) {
        console.log(this.campaignDateEnd);
        this.campaignDateEnd = null;
        // error date
        this.check.campaignDateEnd = true;

        this.$swal({
          title: "Error!",
          text: " End date should be greater than Start date",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        if (
          this.campaignName &&
          this.campaignDateStart &&
          this.campaignDateEnd &&
          this.campaignNumber &&
          this.campaignHour &&
          this.campaignCode
        ) {
          Swal.fire({
            title: "ยืนยันการบันทึกข้อมูล",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: () => {},
          }).then((result) => {
            if (result.isConfirmed) {
              let formData = new FormData();
              formData.append("file", this.fileImage);
              formData.append("campaignName", this.campaignName);
              formData.append("campaignDetail", this.campaignDetail);
              formData.append("campaignCode", this.campaignCode);
              formData.append("campaignDateStart", this.campaignDateStart);
              formData.append("campaignDateEnd", this.campaignDateEnd);
              formData.append("campaignNumber", this.campaignNumber);
              formData.append("campaignStatus", this.campaignStatus);
              formData.append("campaignHour", this.campaignHour); //-----------สำคัญ-----------//
              formData.append("campaignType", "hour"); //-----------สำคัญ-----------//

              console.log(this.fileImage);
              console.log(this.campaignName);
              console.log(this.campaignDetail);
              console.log(this.campaignCode);
              console.log(this.campaignDateStart);
              console.log(this.campaignDateEnd);
              console.log(this.campaignNumber);
              console.log(this.campaignStatus);
              // console.log(formData);
              api.post("/campaignsadd", formData).then((response) => {
                console.log("response", response);
                if (response) {
                  Swal.fire({
                    icon: "success",
                    title: "success",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.dialog1 = false;
                  this.refresh();
                  this.reset();
                }
              });
            }
          });
        } else {
          if (!this.campaignName) {
            this.check.campaignName = true;
            console.log("campaignName null");
          }
          if (!this.campaignDateStart) {
            this.check.campaignDateStart = true;
            console.log("campaignDateStart null");
          }
          if (!this.campaignDateEnd) {
            this.check.campaignDateEnd = true;
            console.log("campaignDateEnd null");
          }
          if (!this.campaignCode) {
            this.check.campaignCode = true;
            console.log("campaignCode null");
          }

          if (!this.campaignNumber) {
            this.check.campaignNumber = true;
            console.log("campaignNumber null");
          }
          if (!this.campaignHour) {
            this.check.campaignHour = true;
            console.log("campaignHour null");
          }
          this.$swal({
            title: "Please complete the information.",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    reset() {
      this.campaignImage = null;
      this.campaignName = null;
      this.campaignDetail = null;
      this.campaignDateStart = null;
      this.campaignDateEnd = null;
      this.campaignCode = null;
      this.campaignNumber = null;
      this.campaignHour = null;
    },
  },
  setup() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
