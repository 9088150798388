<template>
  <div>
    <!-- button  -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-multi-11
      variant="outline-primary"
      @click="reset()"
    >
      Import file
    </b-button>

    <!-- modal -->
    <b-modal
      v-model="dialog7"
      id="modal-multi-11"
      size="lg"
      title="  Import file"
      ok-only
      ok-title="Accept"
    >
      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-form enctype="multipart/form-data" @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding form-item-section">
                <b-row ref="row" class="pb-2">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Campaign Name">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignName"
                            v-model="campaignName"
                            type="text"
                            placeholder="name"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignName == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="limit">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignNumber"
                            v-model="campaignNumber"
                            type="number"
                            placeholder="limit"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignNumber == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Campaign Detail">
                        <b-input-group class="input-group-merge">
                          <quill-editor
                            :options="snowOption"
                            id="campaignDetail"
                            v-model="campaignDetail"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignDetail == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group><br><br><br>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Hour">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignHour"
                            v-model="campaignHour"
                            type="number"
                            placeholder="Campaign Hour"
                            step="0.01"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignHour == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign status">
                        <b-form-checkbox
                          v-model="campaignStatus"
                          checked="true"
                          class="custom-control-success"
                          name="check-button"
                          switch
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <div
                        class="
                          d-flex
                          flex-sm-row flex-column flex-wrap
                          justify-content-between
                          mb-1 mb-sm-0
                        "
                      >
                        <h6>
                          <input
                            type="file"
                            id="file"
                            ref="file"
                            accept=".xlxs,.csv"
                            v-on:change="handleFileUpload()"
                          />
                          <b-alert
                            v-if="check.file == true"
                            variant="danger"
                            show
                          >
                            <span>file null</span>
                          </b-alert>
                        </h6>
                        <a href="/files/import_code.csv" download
                          ><h6 class="text-danger text-sm">
                            ดาวน์โหลด template
                          </h6></a
                        >
                      </div>

                      <!-- //************************ */ -->
                      <b-form-input
                        v-if="amount"
                        id="readOnlyInput"
                        :value="amount"
                        readonly
                      />

                      <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                      </b-table>
                      <b-card-body
                        class="d-flex justify-content-between flex-wrap pt-0"
                      >
                        <!-- page length -->
                        <b-form-group
                          label="Per Page"
                          label-cols="6"
                          label-align="left"
                          label-size="sm"
                          label-for="sortBySelect"
                          class="text-nowrap mb-md-0 mr-1"
                        >
                          <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                          />
                        </b-form-group>

                        <!-- pagination -->
                        <div>
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </div>
                      </b-card-body>
                      <!-- //************************ */ -->
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Date Start">
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="campaignDateStart"
                            v-model="campaignDateStart"
                            placeholder="Campaign Date Start"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignDateStart == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Date End">
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="campaignDateEnd"
                            v-model="campaignDateEnd"
                            placeholder="Campaign Date End"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                        </b-input-group>
                        <b-alert
                          v-if="check.campaignDateEnd == true"
                          variant="danger"
                          show
                        >
                          <span>Please Enter</span>
                        </b-alert>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <div v-if="campaignImage == null">
                        <b-row class="match-height">
                          <b-col>
                            <b-container fluid class="p-1 bg-dark">
                              <b-row>
                                <b-col>
                                  <b-img
                                    thumbnail
                                    fluid
                                    :src="
                                      require('@/assets/images/img/cloud-computing.png')
                                    "
                                  />
                                </b-col>
                              </b-row>
                            </b-container>
                            <b-form-file
                              placeholder="อัพโหลดรูป"
                              ref="refInputEl"
                              type="file"
                              accept=".jpeg,.png,.jpg,GIF"
                              :hidden="true"
                              @change="onFileChange"
                            />
                          </b-col>
                        </b-row>
                      </div>
                      <div class="pa-5" id="Box" v-else>
                        <b-row class="match-height">
                          <b-col>
                            <b-container fluid class="p-1 bg-dark">
                              <b-row>
                                <b-col>
                                  <b-img thumbnail fluid :src="campaignImage" />
                                </b-col>
                              </b-row>
                            </b-container>
                            <b-form-file
                              placeholder="เปลี่ยนรูปภาพ"
                              ref="refInputEl"
                              type="file"
                              accept=".jpeg,.png,.jpg,GIF"
                              :hidden="true"
                              @change="onFileChange"
                            />
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>

                    <b-col cols="5">
                      <br /><br />
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="gennarate()"
                      >
                        Submit
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        @click="reset()"
                      >
                        Reset
                      </b-button>
                    </b-col>
                  </b-row>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BLink,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BModal,
  BFormText,
  BTable,
  BAlert,
  BFormSelect,
  BPagination,
  pagination,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import "animate.css";
import api from "@/api";
import flatPickr from "vue-flatpickr-component";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BLink,
    BListGroupItem,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BModal,
    BFormText,
    BTable,
    BAlert,
    BFormDatepicker,
    BFormSelect,
    BPagination,
    pagination,
    BFormCheckbox,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      data: {},
      campaignImage: null,
      campaignStatus: true,
      campaignName: null,
      campaignDetail: null,
      campaignDateStart: null,
      campaignDateEnd: null,
      campaignCode: null,
      campaignNumber: 1,
      campaignHour: null,
      campaignType: "hour",
      dialog7: null,
            snowOption: {
        theme: 'snow',
      },
      check: {
        campaignImage: false,
        campaignName: false,
        campaignDetail: false,
        campaignDateStart: false,
        campaignDateEnd: false,
        campaignCode: false,
        campaignNumber: false,
        campaignHour: false,
        file: false,
      },
      campaign: [],
      fields: [
        {
          key: "﻿campaign_code",
          label: "Campaign Code",
          sortable: false,
        },
      ],
      items: [],
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      amount: null,
    };
  },
  mounted() {},
  created() {},
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleFileUpload() {
      console.log("cilck");
      this.file = this.$refs.file.files[0];
      console.log("file", this.file);
      let formData = new FormData();
      formData.append("file", this.file);
      api
        .post("/importfile", formData)
        .then((response) => {
          console.log((this.items = response.data.data));
          console.log((this.totalRows = response.data.total));
          this.amount = "จำนวนโค้ดทั้งหมด " + this.totalRows + " โค้ด";
          console.log(this.items[0]["﻿campaign_code"]);
          if (this.items[0]["﻿campaign_code"] == null) {
            console.log("ข้อมูลไม่ถูกต้อง");
            this.totalRows = null;
            this.items = null;
            this.file = null;
            this.amount = null;
            this.$swal({
              title: "Error!",
              text: "ข้อมูลในไฟล์ไม่ถูกต้อง",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: "Error!",
            text: "ข้อมูลในไฟล์ไม่ถูกต้อง",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.totalRows = null;
          this.items = null;
          this.file = null;
          this.amount = null;
        });
    },
    onFileChange(e) {
      this.fileImage = e.target.files[0];
      console.log("fileImage", this.fileImage);
      const image = e.target.files[0];
      this.campaignImage = URL.createObjectURL(image); ///show
    },
    gennarate() {
      if (
        Date.parse(this.campaignDateStart) > Date.parse(this.campaignDateEnd)
      ) {
        console.log(this.campaignDateEnd);
        this.campaignDateEnd = null;
        // error date
        this.check.campaignDateEnd = true;

        this.$swal({
          title: "Error!",
          text: " End date should be greater than Start date",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        if (
          this.campaignName &&
          this.campaignDateStart &&
          this.campaignDateEnd &&
          this.file &&
          this.campaignNumber
        ) {
          Swal.fire({
            title: "ยืนยันมูลในการ Genarate",
            text: "",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ยืนยัน",
            cancelButtonText: "ยกเลิก",
            showLoaderOnConfirm: true,
            preConfirm: () => {},
          }).then((result) => {
            this.dialog7 = true;
            console.log(this.dialog7);
            if (result.isConfirmed) {
              ///******insert  >>>>>>>>>>> campaign */
              let formData = new FormData();
              formData.append("file", this.fileImage);
              formData.append("campaignName", this.campaignName);
              formData.append("campaignDetail", this.campaignDetail);
              formData.append("campaignDateStart", this.campaignDateStart);
              formData.append("campaignDateEnd", this.campaignDateEnd);
              formData.append("campaignNumber", this.campaignNumber);
              formData.append("campaignStatus", this.campaignStatus);
              formData.append("campaignHour", this.campaignHour); //-----------สำคัญ-----------//
              formData.append("campaignType", "hour"); //-----------สำคัญ-----------//
              api.post("/campaignsadd", formData).then((response) => {
                if (response) {
                  const params = {
                    campain_id: response.data,
                    total: this.totalRows,
                    data: this.items,
                    campaignNumber: this.campaignNumber,
                  };
                  console.log("params", params);
                  api
                    .post("/campaignadddatagenarate", params)
                    .then((response) => {
                      this.refresh();
                      console.log(response.data);
                      Swal.fire({
                        icon: "success",
                        title: "success",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      this.dialog7 = false;
                      console.log(this.dialog7);
                      this.reset();
                    });
                }
              });

              ///******insert  >>>>>>>>>>> codes */
            }
          });
        } else {
          if (!this.campaignName) {
            this.check.campaignName = true;
            console.log("campaignName null");
          }
          if (!this.campaignDateStart) {
            this.check.campaignDateStart = true;
            console.log("campaignDateStart null");
          }
          if (!this.campaignDateEnd) {
            this.check.campaignDateEnd = true;
            console.log("campaignDateEnd null");
          }
          if (!this.file) {
            this.check.file = true;
            console.log("file null");
          }

          if (!this.campaignNumber) {
            this.check.campaignNumber = true;
            console.log("campaignNumber null");
          }
          this.$swal({
            title: "Please complete the information.",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        }
      }
    },
    reset() {
      this.amount = null;
      this.campaignImage = null;
      this.campaignName = null;
      this.campaignDetail = null;
      this.campaignDateStart = null;
      this.campaignDateEnd = null;
      this.campaignCode = null;
      this.campaignNumber = null;
      this.campaignHour = null;
      this.campaign = [];
      this.file = null;
      this.totalRows = null;
      this.items = null;
      this.check.campaignImage = false;
      this.check.campaignName = false;
      this.check.campaignDetail = false;
      this.check.campaignDateStart = false;
      this.check.campaignDateEnd = false;
      this.check.campaignCode = false;
      this.check.campaignNumber = false;
      this.check.campaignHour = false;
      this.check.file = false;
    },
    save() {
      Swal.fire({
        title: "ยืนยันการบันทึกข้อมูล",
        text: "",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showLoaderOnConfirm: true,
        preConfirm: () => {},
      }).then((result) => {
        if (result.isConfirmed) {
          let formData = new FormData();
          formData.append("file", this.fileImage);
          formData.append("campaignName", this.campaignName);
          formData.append("campaignDetail", this.campaignDetail);
          formData.append("campaignDateStart", this.campaignDateStart);
          formData.append("campaignDateEnd", this.campaignDateEnd);
          formData.append("campaignNumber", this.campaignNumber);
          formData.append("campaignHour", this.campaignHour); //-----------สำคัญ-----------//
          formData.append("campaignType", "hour"); //-----------สำคัญ-----------//
          api.post("/campaignsadd", formData).then((response) => {
            console.log("response", response);
            if (response) {
              const params = {
                campain_id: response.data,
                total: this.campaignNumber,
                data: this.campaign,
              };
              console.log("params", params);
              api.post("/campaignadddatagenarate", params).then((response) => {
                this.refresh();
                console.log(response.data);
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.refresh();
              });
            }
          });
        }
      });
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
  },
  setup() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>