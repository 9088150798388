<template>
  <div>
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="show = true"
    >
      Show Modal
    </b-button> -->

    <b-modal v-model="show" size="lg" :title="campaignName">
      <b-container fluid>
        <b-tabs>
          <b-tab title="Tag Create">
            <b-form-group label="Tag">
              <b-form-tags v-model="tag" input-id="tags-basic" class="mb-2" />
            </b-form-group>
          </b-tab>
          <b-tab active title="Tag Venue">
            <b-form-group label="Venue dropdown">
              <b-form-tags v-model="venue" no-outer-focus>
                <template v-slot="{ tags, disabled, addTag, removeTag }">
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mb-1"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        :title="tag"
                        :disabled="disabled"
                        variant="primary"
                        @remove="removeTag(tag)"
                      >
                        {{ tag }}
                      </b-form-tag>
                    </li>
                  </ul>

                  <b-dropdown
                    size="sm"
                    variant="outline-secondary"
                    block
                    menu-class="w-100"
                  >
                    <template v-slot:button-content> Choose tags </template>
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                      <b-form-group
                        label-for="tag-search-input"
                        label="Search tags"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                        :description="searchDesc"
                        :disabled="disabled"
                      >
                        <b-form-input
                          id="tag-search-input"
                          v-model="search"
                          type="search"
                          size="sm"
                          autocomplete="off"
                        />
                      </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider />
                    <b-dropdown-item
                      v-for="option in availableOptions"
                      :key="option"
                      @click="onOptionClick({ option, addTag })"
                    >
                      {{ option }}
                    </b-dropdown-item>
                    <b-dropdown-text v-if="availableOptions.length === 0">
                      There are no tags available to select
                    </b-dropdown-text>
                  </b-dropdown>
                </template>
              </b-form-tags>
            </b-form-group>
          </b-tab>
          <b-tab title="map"> </b-tab>
        </b-tabs>
      </b-container>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="save()"
          >
            save
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="float-right"
            @click="show = false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormTags,
  BFormGroup,
  BDropdown,
  BDropdownForm,
  BFormInput,
  BDropdownDivider,
  BDropdownItem,
  BFormTag,
  BDropdownText,
  BTabs,
  BTab,
  BCardText,
  BButton,
  BModal,
  VBModal,
  BContainer,
  BRow,
  BCol,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Swal from "sweetalert2";
import "animate.css";
import api from "@/api";
import { CloudSnowIcon } from "vue-feather-icons";

export default {
  components: {
    BFormTags,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BFormInput,
    BDropdownDivider,
    BDropdownItem,
    BFormTag,
    BDropdownText,
    BTabs,
    BCardText,
    BTab,
    BButton,
    BModal,
    VBModal,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    id: null,
    show: false,
    options: [],
    search: "",
    venue: [],
    tag: [],
    items: [],
    campaignName: null,
  }),
  mounted() {
    api.post("/getvenue").then((response) => {
      for (var key in response.data.data) {
        this.options.push(response.data.data[key]["title"]);
      }
    });
  },
  computed: {
    criteria() {
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const { criteria } = this;
      const options = this.options.filter(
        (opt) => this.venue.indexOf(opt) === -1
      );
      if (criteria) {
        return options.filter(
          (opt) => opt.toLowerCase().indexOf(criteria) > -1
        );
      }
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return "There are no tags matching your search criteria";
      }
      return "";
    },
  },
  methods: {
    save() {
      console.log("save");
      console.log(this.tag);
      console.log(this.venue);
      if (this.venue.length == 0 && this.tag.length == 0) {
        this.$swal({
          title: "Error!",
          text: " Please complete the information.",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        Swal.fire({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => {},
        }).then((result) => {
          if (result.isConfirmed) {
            this.items = [];
            for (let i = 0; i < this.tag.length; i++) {
              console.log(this.tag[i]);
              this.items.push(this.tag[i]);
            }
            for (let i = 0; i < this.venue.length; i++) {
              console.log(this.venue[i]);
              this.items.push(this.venue[i]);
            }
            const params = {
              campaign_id: this.id,
              data: this.items,
            };
            api.post("/addtag", params).then((response) => {
              console.log("response", response.data);
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.show = false;
                (this.venue = []), (this.tag = []), this.refresh();
              }
            });
          }
        });
      }
    },
    async showmodeladdtag(id) {
      console.log("id", id);
      this.id = id;
      this.venue = []
      this.tag = []
      const params = {
        id: id,
      };
      api.post("/gettag", params).then((response) => {
        for (var key in response.data.data) {
          console.log(this.venue)
          console.log(this.venue.includes(response.data.data[key]["tag"]))
          if (this.options.includes(response.data.data[key]["tag"])) {
            this.venue.push(response.data.data[key]["tag"]);
          } else {
            this.tag.push(response.data.data[key]["tag"]);
          }
        }
      });
      api.post("/campaignsget", params).then((response) => {
        for (let item of response.data.data) {
          this.campaignName =
            "ตำแหน่งพิกัดที่จะโชว์คูปองของ : " +
            item.campaign_name +
            " วันที่ " +
            item.campaign_dateStart +
            " - " +
            item.campaign_dateEnd;
        }
      });

      this.show = true;
      console.log("id", id);
      this.id = id;
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    onOptionClick({ option, addTag }) {
      addTag(option);
      this.search = "";
    },
  },
};
</script>
 