<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      title="Detail"
      ok-only
      ok-title="Exit"
      no-stacking
    >
      <b-row class="invoice-add">
        <b-col cols="12" xl="12" md="12">
          <b-form enctype="multipart/form-data" @submit.prevent>
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding form-item-section">
                <b-row ref="row" class="pb-2">
                  <b-row>
                    <b-col cols="6">
                      <b-form-group label="Campaign Name">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignName"
                            v-model="campaignName"
                            type="text"
                            placeholder="name"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Total">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignNumber"
                            v-model="campaignNumber"
                            type="number"
                            placeholder="Total"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Detail">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                          </b-input-group-prepend>
                          <b-form-textarea
                            id="campaignDetail"
                            v-model="campaignDetail"
                            placeholder="Campaign Detail"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group label="Campaign Hour">
                        <b-input-group class="input-group-merge">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input
                            id="campaignHour"
                            v-model="campaignHour"
                            type="number"
                            placeholder="Campaign Hour"
                            step="0.01"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group label="Campaign status">
                        <b-form-checkbox
                          v-model="campaignStatus"
                          checked="true"
                          class="custom-control-secondary"
                          name="check-button"
                          switch
                          disabled
                        >
                          <span class="switch-icon-left">
                            <feather-icon icon="CheckIcon" />
                          </span>
                          <span class="switch-icon-right">
                            <feather-icon icon="XIcon" />
                          </span>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Date Start" disabled>
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            id="campaignDateStart"
                            v-model="campaignDateStart"
                            placeholder="Campaign Date Start"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Campaign Date End" disabled>
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                            disabled
                            id="campaignDateEnd"
                            v-model="campaignDateEnd"
                            placeholder="Campaign Date End"
                            class="form-control"
                            :config="{
                              enableTime: true,
                              dateFormat: 'Y-m-d H:i',
                            }"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <div class="pa-5" id="Box">
                        <b-row class="match-height">
                          <b-col>
                            <b-container fluid class="p-1 bg-dark">
                              <b-row>
                                <b-col>
                                  <b-img thumbnail fluid :src="campaignImage" />
                                </b-col>
                              </b-row>
                            </b-container>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                    <b-col cols="6">
                      <b-form-input
                        id="readOnlyInput"
                        :value="amount"
                        readonly
                      />
                      <b-table
                        striped
                        hover
                        responsive
                        class="position-relative"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                      >
                      </b-table>
                      <b-card-body
                        class="d-flex justify-content-between flex-wrap pt-0"
                      >
                        <!-- page length -->
                        <b-form-group
                          label="Per Page"
                          label-cols="6"
                          label-align="left"
                          label-size="sm"
                          label-for="sortBySelect"
                          class="text-nowrap mb-md-0 mr-1"
                        >
                          <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            inline
                            :options="pageOptions"
                          />
                        </b-form-group>

                        <!-- pagination -->
                        <div>
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mb-0"
                          >
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </div>
                      </b-card-body>
                      <!-- //************************ */ -->
                    </b-col>
                    <b-col cols="12"
                      ><br />
                      <label for="tags-pills">{{ tetil }}</label>
                      <b-form-tags
                        disabled
                        v-model="value"
                        input-id="tags-pills"
                        tag-variant="primary"
                        tag-pills
                        size="lg"
                        separator=" "
                        placeholder=""
                        class="mb-2"
                      />
                    </b-col>
                  </b-row>
                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BLink,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BModal,
  BFormText,
  BTable,
  BAlert,
  BFormSelect,
  BPagination,
  BFormCheckbox,
  pagination,
  BFormTags,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import flatPickr from "vue-flatpickr-component";
export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BLink,
    BListGroupItem,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BModal,
    BFormText,
    BTable,
    BAlert,
    BFormDatepicker,
    BFormSelect,
    BPagination,
    pagination,
    BFormCheckbox,
    BFormTags,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      data: {},
      campaignImage: null,
      campaignName: null,
      campaignDetail: null,
      campaignDateStart: null,
      campaignDateEnd: null,
      campaignCode: null,
      campaignNumber: null,
      campaignHour: null,
      dialog: null,
      campaignStatus: null,
      fields: [
        {
          key: "code",
          label: "Campaign Code",
          sortable: false,
        },
        {
          key: "code_status",
          label: "status",
          sortable: false,
        },
      ],
      items: [],
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      value: [],
      tetil: null,
      amount: null,
    };
  },
  mounted() {},
  created() {},
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    async showModaldata(id) {
      console.log("id", id);
      const params = {
        id: id,
      };
      api.post("/campaignsget", params).then((response) => {
        console.log(response.data);
        console.log((this.items = response.data.codes));
        console.log((this.totalRows = response.data.total));
        this.amount = "จำนวนโค้ดทั้งหมด " + this.totalRows + " โค้ด";
        for (let item of response.data.data) {
          (this.campaignName = item.campaign_name),
            (this.campaignDetail = item.campaign_detail),
            (this.campaignDateStart = item.campaign_dateStart),
            (this.campaignDateEnd = item.campaign_dateEnd),
            (this.campaignNumber = item.campaign_number),
            (this.campaignStatus = item.campaign_status),
            (this.campaignImage = item.campaign_image);
          this.campaignHour = item.campaign_hour;
          this.tetil =
            "ตำแหน่งพิกัดที่จะโชว์คูปองของ : " +
            item.campaign_name +
            " วันที่ " +
            item.campaign_dateStart +
            " - " +
            item.campaign_dateEnd;
          console.log(this.tetil);
          this.value = [];
          api.post("/gettag", params).then((response) => {
            for (var key in response.data.data) {
              this.value.push(response.data.data[key]["tag"]);
            }
          });
        }
      });
      this.dialog = true;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  setup() {},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>